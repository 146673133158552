.via-stops-row {
  margin-top: 32px;
  color: var(--db-cool-gray-800);
  overflow: hidden;
  position: relative;
  /* width: 100%; */
  line-height: 42px;
}

.via-stops-row.landscape {
  margin-top: 22px;
}

.companion-via-stops > .via-stops-row {
  margin-top: 29px;
}

.companion-via-stops.landscape > .via-stops-row {
  margin-top: 20px;
}

.via-stops-row > .via-left-gradient {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  opacity: 0;
  transition: all 0.5s;
  background-image: linear-gradient(90deg, #ffffffff, #ffffff00);
  z-index: 1;
}

.via-stops-row > .via-right-gradient {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 20px;
  background-image: linear-gradient(90deg, #ffffff00, #ffffffff);
  z-index: 1;
}

.via-stops-row > .via-gray {
  background-image: linear-gradient(90deg, #f0f3f5ff, #f0f3f500);
}

.via-stops-row > .via-gray-right {
  background-image: linear-gradient(90deg, #f0f3f500, #f0f3f5ff);
}

.via-stops-row.landscape > .via {
  font-size: 44px;
  line-height: 56px;
}

.via-stops-row > .via {
  height: 100%;
  white-space: nowrap;
  animation: inifinite-horizontal-scroll 30s infinite 2s linear;
  transform: translate3d(0, 0, 0);
  font-size: 42px;
  line-height: 42px;
  letter-spacing: -0.3px;
  margin: 0;
  display: inline-block;
}

.via-stops-row > .via-no-anim {
  animation: none;
  transform: translate3d(0px, 0, 0);
}

.via-stops-row > .via-anim {
  display: inline-block;
  margin: 0;
  padding: 0;
}

@keyframes strike {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.canceled-via-stop {
  position: relative;
}

.canceled-via-stop::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 4px;
  background: #131821;
  animation-name: strike;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  /* animation-fill-mode: forwards; */
}

@keyframes inifinite-horizontal-scroll {
  0% {
    transform: translate3d(0px, 0, 0);
  }

  100% {
    transform: translate3d(calc(-50% + 0px), 0, 0);
  }
}
