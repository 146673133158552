.header {
  display: flex;
  align-items: center;
  height: 200px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: var(--db-cool-gray-800);
}

.header > .data-holder {
  color: #ffffff;
  display: flex;
  align-items: baseline;
  font-size: 76px;
  line-height: 76px;
  position: relative;
  letter-spacing: -1.58px;
  width: 100%;
}

.header > .data-holder .bold {
  font-family: db-screen-sans-bold, sans-serif;
}

.header > .data-holder > .station-host {
  margin-left: 200px;
  overflow: hidden;
  position: relative;
  font-size: 76px;
  line-height: 96px;
  flex-grow: 1;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.header > .data-holder > .station-host > .station {
  text-align: right;
  white-space: nowrap;
  animation: none;
}

.header > .data-holder > .station-host > .station.animated {
  /* right: unset; */
  /* margin-top: 50%; */
  width: 805px;
  animation: 10s bounce-header ease-in-out infinite;
  animation-delay: 2s;
}

.header > .data-holder > .station-host > .left-gradient {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 72px;
  width: 120px;
  opacity: 0;
  background-image: linear-gradient(90deg, #000000, #00000000);
  animation: 10s header-left-gradient-alpha infinite;
  animation-delay: 2s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  z-index: 1;
}

.header > .data-holder > .station-host > .right-gradient {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 72px;
  width: 120px;
  opacity: 1;
  background-image: linear-gradient(90deg, #00000000, #000000);
  animation: 10s header-right-gradient-alpha infinite;
  animation-delay: 2s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  z-index: 1;
}

.header > .data-holder > .time {
  display: flex;
}

.header > .data-holder > .time > .blink {
  transform: translateY(-8px);
}

@keyframes header-left-gradient-alpha {
  0%,
  10%,
  90%,
  100% {
    opacity: 0;
  }
  40%,
  60% {
    opacity: 1;
  }
}

@keyframes header-right-gradient-alpha {
  0%,
  10%,
  90%,
  100% {
    opacity: 1;
  }
  40%,
  60% {
    opacity: 0;
  }
}

@keyframes bounce-header {
  0%,
  10%,
  90%,
  100% {
    left: 0%;
    transform: translate(0, 0%);
  }
  40%,
  60% {
    left: 100%;
    /* top: 50%; */
    transform: translate(-50%, 0%);
  }
}

.header > .data-holder > .title {
  margin-left: 80px;
}

.header > .data-holder > .translated-title {
  margin-left: 44px;
  font-size: 68px;
  line-height: 76px;
  letter-spacing: -1.7px;
}

.header > .data-holder > .time > .blink {
  animation: blink-animation 2s steps(3, start) infinite;
  -webkit-animation: blink-animation 2s steps(3, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
