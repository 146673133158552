.travel-center-board-item {
  display: grid;
  height: 282px;
  grid-template-columns: 240px 1630px 110px;
  padding-left: 50px;
  padding-right: 50px;
  align-items: start;
  position: absolute;
  transition: top 1000ms, opacity 1000ms, background-color 1000ms;
  transition-timing-function: linear;
  background-color: white;
  opacity: 0;
}

.travel-center-board-item.landscape {
  grid-template-columns: 238px 1414px 110px;
  height: 294px;
}

.travel-center-board-item.gray {
  background-color: var(--db-row-gray-bg);
}

.travel-center-board-item .updated {
  color: var(--db-red-500) !important;
}

.travel-center-board-item.landscape > .platform {
  font-size: 72px;
  line-height: 80px;
}

.travel-center-board-item > .platform {
  font-size: 70px;
  line-height: 70px;
  display: block;
  justify-self: end;
  color: var(--db-cool-gray-800);
  margin-top: 30px;
  position: absolute;
  right: 50px;
}

.travel-center-board-item.landscape > .time-holder {
  font-size: 72px;
  line-height: 80px;
}

.travel-center-board-item > .time-holder {
  font-size: 70px;
  line-height: 70px;
  margin-top: 30px;
  transition: margin 400ms;
}

.travel-center-board-item.landscape > .time-holder > .time {
  margin-top: 0px;
  font-size: 72px;
  line-height: 80px;
}

.travel-center-board-item > .time-holder > .time {
  color: var(--db-light-green-600);
  margin-top: 14px;
}

.travel-center-board-item > .time-holder > .time-schedule {
  color: var(--db-cool-gray-800);
}

.travel-center-board-item > .data-holder > .destination-row {
  display: flex;
  margin-top: 26px;
  margin-right: 50px;
  height: 80px;
}

.travel-center-board-item.landscape > .data-holder > .destination-row {
  margin-top: 30px;
}

.display-enter {
  opacity: 0;
}

.display-enter-active {
  transition-delay: 250ms;
  opacity: 1;
  transition: all 400ms ease-in;
}

.display-exit {
  opacity: 1;
}

.display-exit-active {
  opacity: 0;
  transition: all 400ms ease-in;
}
