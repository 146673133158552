.right-panel {
  background-color: #ffffff;
  margin: 40px 40px 40px 20px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  width: 1860px;
  /* height: 1820px; */
  opacity: 1;
  overflow: hidden;
  position: relative;
}

.right-panel > .sub-header {
  display: grid;
  padding: 32px 50px;
  height: 50px;
  grid-template-columns: 1fr 1fr;
  z-index: 100;
  background-color: white;
  border-bottom: 4px solid var(--db-cool-gray-200);
  transition: border-bottom 100ms;
  position: relative;
}

.right-panel > .sub-header.borderless {
  border-bottom: 4px solid white;
}

.right-panel > .sub-header-shadow {
  height: 15px;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  left: 40px;
  right: 40px;
  position: absolute;
  z-index: 100;
  opacity: 0;
  transition: opacity 100ms;
}

.right-panel > .sub-header-shadow.visible {
  opacity: 1;
}

.right-panel > .sub-header > div {
  display: block;
  font-size: 48px;
}

.right-panel > .head > .column-name {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.right-panel > .sub-header > .column-name {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: 1s;
}

.right-panel > .sub-header > .column-name.fade-out {
  opacity: 0;
}

.right-panel > .sub-header > .column-name .top {
  font-size: 40px;
  line-height: 40px;
  /* letter-spacing: -0.2px; */
  color: #3c414b;
  margin-right: 12px;
}

.right-panel > .sub-header > .column-name .bottom {
  font-size: 40px;
  line-height: 40px;
  color: #3c414b;
  opacity: 0.7;
  /* margin-top: 10px; */
}

.right-panel > .sub-header > .time {
  grid-column: 1 / 2;
}

.right-panel > .sub-header > .train {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.right-panel > .pages {
  width: 1860px;
  justify-content: flex-start;
  display: flex;
}

.right-panel > .pages.sliding-transition {
  transition: transform 1.5s ease-in-out;
}

.right-panel > .pages > .travel-center-list {
  /* z-index: -1; */
  overflow: hidden;
  height: 1764px;
  position: relative;
  width: 1860px;
  flex-shrink: 0;
}

.right-panel > div > div:last-child {
  border-radius: 0 0 16px 16px;
}
/* 
.fade-appear {
  opacity: 1;
  transform: translateY(0px);
  transition: none;
}

.fade-appear-active {
  opacity: 1;
  transform: translateY(0px);
  transition: none;
} */

.fade-enter {
  max-height: 0px;
  /* transform: translateY(282px); */
  transform-origin: top;

  opacity: 0;
}

.fade-enter-active {
  max-height: 282px;
  opacity: 1;
  transform: translateY(0px);
  transform-origin: top;

  transition: all 1000ms ease-in;
}

.fade-exit {
  max-height: 282px;
  transform: translateY(0px);
  transform-origin: top;
  z-index: -1;
  opacity: 1;
}

.fade-exit-active {
  max-height: 282px;
  opacity: 0;
  z-index: -1;
  transform: translateY(-282px);
  transform-origin: top;
  transition: all 1000ms ease-in;
}
