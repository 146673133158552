.pagination-indicator-host {
  display: flex;
  gap: 10px;
}

.pagination-indicator-host > .indicator-outer {
  width: 24px;
  height: 24px;
  background-color: var(--db-cool-gray-200);
  border-radius: 12px;
  transition: 0.5s;
}

.pagination-indicator-host > .indicator-outer.active {
  width: 128px;
}

.pagination-indicator-host > .indicator-outer > .indicator-inner {
  background-color: var(--db-cool-gray-200);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  transition: 0.5s;
}

.pagination-indicator-host > .indicator-outer > .indicator-inner.active {
  background-color: var(--db-cool-gray-500);
  width: 128px;
  height: 24px;
  border-radius: 12px;
  transition: width 15s linear, background-color 0s linear;
}
