.train-chip-holder {
  width: 372px;
  height: 80px;
  position: relative;
  overflow: visible;
}

.train-chip-holder.lufthansa {
  height: 160px;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  background: linear-gradient(#282d37 50%, transparent 50%);
  border: 5px solid #282d37;
}

.train-chip-holder.lufthansa.canceled {
  border: 5px solid var(--db-cool-gray-500);
}

.train-chip-holder.lufthansa.canceled > .canceled {
  border: 2px solid var(--db-cool-gray-500);
}

.train-chip-holder > .train-chip {
  width: 372px;
  height: 80px;
  border-radius: 12px;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
  color: white;
  padding-bottom: 4px;
  background-color: var(--db-cool-gray-800);
  backface-visibility: hidden;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
}

.train-chip-holder.lufthansa > .train-chip {
  border-radius: 0px;
  margin-top: -5px;
  margin-left: -5px;
  position: absolute;
}

.train-chip-holder {
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s ease-in-out;
}

.train-chip-holder > div {
  padding: 1px 0px;
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 8px;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 1px;
}

.train-chip-holder > .lufthansa {
  top: 80px;
  background-color: transparent;
  color: #282d37;
}

.train-chip-holder > .replaced {
  transform: rotateX(180deg);
  background-color: transparent;
}

.train-chip-holder > .ice {
  background-color: #282d37;
}

.train-chip-holder > .ec {
  background-color: #646973;
}

.train-chip-holder > .regio {
  background-color: #878c96;
}
.train-chip-holder > .city-train {
  background-color: #2a7230;
}

.train-chip-holder > .subway {
  background-color: #1455c0;
}

.train-chip-holder > .tram {
  background-color: #a9455d;
}

.train-chip-holder > .bus {
  background-color: #814997;
}

.train-chip-holder > .stb {
  background-color: #1455c0;
}

.train-chip-holder > .ersatz {
  background-color: #9b1b60;
}

.train-chip-holder > .canceled {
  color: var(--db-cool-gray-500);
  border: 5px solid var(--db-cool-gray-500);
}

.canceled.white-chip {
  background-color: white;
}

.canceled.gray-chip {
  background-color: var(--db-row-gray-bg);
}

.train-chip-holder > .canceled > span {
  width: max-content;
  position: relative;
}

.train-chip-holder > .canceled > span::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0%;
  height: 5px;
  background: var(--db-cool-gray-500);
  animation-name: strike-dest;
  animation-delay: 0.3s;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes strike-dest {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.is-flipped {
  transform: rotateX(-180deg);
}

.display-chip-enter {
  opacity: 0;
}

.display-chip-enter-active {
  transition-delay: 250ms;
  opacity: 1;
  transition: all 250ms ease-in;
}

.display-chip-exit {
  opacity: 1;
}

.display-chip-exit-active {
  opacity: 0;
  transition: all 250ms ease-in;
}
