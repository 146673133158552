@font-face {
  font-family: db-screen-sans-regular;
  font-weight: 400;
  src: url(./assets/fonts/DBScreenSans-Regular.woff2);
}

@font-face {
  font-family: db-screen-sans-bold;
  font-weight: 700;
  src: url(./assets/fonts/DBScreenSans-Bold.woff2);
}

body {
  margin: 0;
  font-family: db-screen-sans-regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #d7dce1;
}

:root {
  --db-row-gray-bg: #f0f3f5;
  --db-cool-gray-100: #f0f3f5;
  --db-cool-gray-200: #d7dce1;
  --db-cool-gray-500: #646973;
  --db-cool-gray-600: #3c414b;
  --db-cool-gray-800: #131821;
  --db-light-green-600: #467a18;
  --db-red-500: #db0014;
  --db-lufthansa: #ffad00;
}

.bold {
  font-family: db-screen-sans-bold, sans-serif;
  font-weight: 700;
}

.reisezentrum-body {
  transform: scale(1);
  transform-origin: top left;
}

@media screen and (width: 1080px) and (height: 1920px) {
  .reisezentrum-body {
    transform: scale(0.5);
    transform-origin: top left;
    width: 2160px;
    height: 3840px;
    overflow: hidden;
  }
}

@media screen and (width: 1920px) and (height: 1080px) {
  body {
    transform: scale(0.5);
    transform-origin: top left;
    width: 3840px;
    height: 2160px;
    overflow: hidden;
  }
}

.landscape-mode {
  display: flex;
}
