.hidden-destination-size-checker {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
  visibility: hidden;
  font-size: 70px;
}

.destination-name.landscape {
  font-size: 72px;
  line-height: 72px;
}

.destination-name {
  font-size: 70px;
  line-height: 70px;
  color: var(--db-cool-gray-800);
  transition: none;
  margin-top: 4px;
}

.destination-name.differed {
  position: relative;
}

.destination-name.differed::after {
  content: " ";
  position: absolute;
  top: 48%;
  left: 0;
  width: 100%;
  height: 8px;
  background: var(--db-cool-gray-800);
  will-change: width;
  animation-name: strike;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.destination-name.differing {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  will-change: opacity;

  opacity: 0;
  animation-fill-mode: forwards;
}

.destination-host {
  position: absolute;
  overflow: hidden;
  height: 90px;
  width: 1106px;
  left: 702px;
  /* flex-grow: 1; */
}

.destination-host > .destination-bouncer {
  position: absolute;
  width: fit-content;
  display: flex;
  left: 0px;
  margin-left: 0px;
  margin-right: 0px;
  animation-iteration-count: infinite;
  animation-duration: 6.3s;
  will-change: transform;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  white-space: nowrap;
  backface-visibility: hidden;
  --translate-percentage: -10%;
  animation-name: bounce-destination;
}

.destination-host > .destination-bouncer.no-animation {
  animation: none;
}

.destination-host > .destination-bouncer > .destination-name {
  margin-left: 0px;
}

.destination-host > .destination-bouncer > .destination-name.destination-name.differing {
  margin-left: 25px;
}

.destination-host > .left-gradient {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  opacity: 0;
  background-image: linear-gradient(90deg, #ffffffff, #ffffff00);
  animation: 6.3s left-gradient-alpha infinite;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  z-index: 1;
}

.destination-host > .left-gradient.gradient-gray {
  background-image: linear-gradient(90deg, #f0f3f5ff, #f0f3f500);
}

.destination-host > .right-gradient.gradient-gray {
  background-image: linear-gradient(90deg, #f0f3f500, #f0f3f5ff);
}

.destination-host > .right-gradient {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 20px;
  opacity: 0;
  background-image: linear-gradient(90deg, #ffffff00, #ffffffff);
  animation: 6.3s right-gradient-alpha infinite;
  animation-delay: 1s;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  z-index: 1;
}

@keyframes bounce-destination {
  0%,
  15% {
    left: 0%;
    transform: translate3d(0, 0, 0);
  }
  85%,
  100% {
    left: 100%;
    transform: translate3d(-100%, 0, 0);
  }
}

/* 
@keyframes bounce-header {
  0%,
  10%,
  90%,
  100% {
    left: 0%;
    transform: translate(0, -50%);
  }
  40%,
  60% {
    left: 100%;
    transform: translate(-100%, -50%);
  }
} */

@keyframes strike {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes left-gradient-alpha {
  0%,
  15% {
    opacity: 0;
  }
  30%,
  100% {
    opacity: 1;
  }
}

@keyframes right-gradient-alpha {
  0%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
