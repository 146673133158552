.landscape-mode > .error-box-holder {
  width: 100%;
  height: calc(200vh - 200px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landscape-mode > .error-box-holder > .error-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landscape-mode > .error-box-holder > .error-box > .title {
  color: #282d37;
  font-family: db-screen-sans-bold, sans-serif;
  font-weight: 700;
  font-size: 76px;
  line-height: 80px;
  letter-spacing: -2px;
  margin-top: 40px;
}

.landscape-mode > .error-box-holder > .error-box > .subline {
  color: #282d37;
  font-size: 36px;
  line-height: 48px;
  margin-top: 40px;
  width: 1000px;
  text-align: center;
}
