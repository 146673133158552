.time-holder.centered {
  margin-top: auto;
  margin-bottom: auto;
}

.companion-first-notification {
  margin-top: 68px;
  margin-bottom: 72px;
  height: 54px;
}

.companion-first-notification.landscape {
  margin-top: 73px;
  margin-bottom: 73px;
  height: 70px;
}

.companion-bracket {
  position: absolute;
  left: 232px;
  top: 65px;
}

.companion-bracket-top {
  position: absolute;
  left: 255px;
  top: 0px;
}

.travel-center-board-item.landscape > div > .companion-bracket {
  top: 70px;
  left: 255px;
}

.companion-second-notification {
  margin-top: 57px;
  margin-bottom: 57px;
  height: 54px;
}
