.notification-row {
  /* margin-top: 16px; */
  color: var(--db-cool-gray-800);
  /* overflow: hidden; */
  position: relative;
  display: flex;
  /* font-size: 42px;
  line-height: 42px; */
  /* background-color: blue; */
  /* width: 100%; */
}

.notification-row > .notification-icon {
  width: 54px;
  /* height: 100; */
  margin-bottom: 2px;
  margin-right: 12px;
  z-index: 1;
  /* background-color: red; */
}

.notification-row > .notification-icon.companion {
  margin-left: -57px;
  margin-right: 5px;
}

.notification-row > .notification-messages-host {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.notification-row > .notification-messages-host > .notification-messages {
  overflow: hidden;
  white-space: nowrap;
  animation: inifinite-horizontal-scroll 30s infinite 2s linear;
  transform: translate3d(0px, 0, 0);
  font-size: 42px;
  height: 100%;
  margin-top: 5px;
  margin-bottom: auto;
  display: inline-block;
}

.notification-row > .notification-messages-host > .notification-messages.notification-no-anim {
  animation: none;
  transform: translate3d(0px, 0, 0);
}

.notification-messages.landscape {
  font-size: 44px !important;
  line-height: 64px !important;
  margin-top: 0px !important;
}

.notification-row > .notification-messages-host > .notification-left-gradient {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  background-image: linear-gradient(90deg, #ffffffff, #ffffff00);
  z-index: 1;
  opacity: 0;
}

.notification-row > .notification-messages-host > .notification-right-gradient {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 20px;
  background-image: linear-gradient(90deg, #ffffff00, #ffffffff);
  z-index: 1;
}

.notification-row > .notification-messages-host > .notification-gray {
  background-image: linear-gradient(90deg, #f0f3f5ff, #f0f3f500);
}

.notification-row > .notification-messages-host > .notification-gray-right {
  background-image: linear-gradient(90deg, #f0f3f500, #f0f3f5ff);
}

.display-enter {
  opacity: 0;
}

.display-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}

.display-exit {
  opacity: 1;
}

.display-exit-active {
  opacity: 0;
  transition: all 500ms ease-in;
}
